
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbsWithParams } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "Dashboard",
  components: {},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams("Dashboard", [])
    })
    return{
      date: new Date().getTime()
    }
  }
})
